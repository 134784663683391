const userAPI = {
    getUserDataByToken: (token) => {
        return fetch(`/api/v1/authorize`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                authToken: token,
            }),
        });
    },
};

export default userAPI;
