//Core
import React, { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
//Actions
import { journalActions } from '../../actions';
import { clearJournalFilesData } from '../../reducers';
//Components
import { DownloadFile, JournalItemsTableAllData, Pagination, Button } from '../../components';
//Helpers
import { getFullDate, addDays } from '../../helpers';
//Styles
import Styles from './styles.module.css';
import 'react-datepicker/dist/react-datepicker.css';

const states = {
    all: [],
    inProgress: ['NOT_SIGNED', 'SIGNED', 'SENT', 'SENT_CUST_BANK'],
    success: ['SUCCESS'],
    rejected: ['CANCELED', 'CANCEL_CUST_BANK', 'DECLINE', 'ERROR'],
    expired: ['EXPIRED'],
};

const JournalAllData = () => {
    const [currentFilter, setCurrentFilter] = useState('all');
    const [keyForPagination, setKeyForPagination] = useState(1);
    const [selectedOrders, selectOrders] = useState(new Set());
    const [IBAN, setIBAN] = useState('');
    const [OKPO, setOKPO] = useState('');
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const journalFileData = useSelector((state) => state.journalData.journalFileData);

    const getParams = () => {
        const params = {};
        if (dateRange[0] && dateRange[1]) {
            params.from = getFullDate(dateRange[0]);
            params.to = getFullDate(dateRange[1]);
        }
        if (states[currentFilter].length) {
            params.state = states[currentFilter];
        }
        if (OKPO) {
            params.debtorOkpo = OKPO;
        }
        if (IBAN) {
            params.debtorAccount = IBAN;
        }
        return params;
    };

    useEffect(() => {
        dispatch(journalActions.getJournalFilesData(navigate));
        return () => {
            dispatch(clearJournalFilesData());
        };
    }, []);

    useEffect(() => {
        if (dateRange[0] && dateRange[1]) {
            const params = getParams();
            dispatch(journalActions.getJournalFilesData(navigate, params));
            setKeyForPagination(keyForPagination + 1);
            selectOrders(new Set());
        }
    }, [dateRange]);

    const selectOrder = (id) => {
        if (selectedOrders.has(id)) {
            selectedOrders.delete(id);
            selectOrders(new Set([...selectedOrders]));
        } else {
            selectOrders(new Set([...selectedOrders.add(id)]));
        }
    };

    const selectAllOrder = (isCheckedAll) => {
        if (isCheckedAll) {
            selectOrders(new Set([]));
            return;
        }
        if (journalFileData?.content) {
            const filteredData = journalFileData.content.filter((item) => !states.inProgress.includes(item.state));
            selectOrders(new Set([...selectedOrders, ...filteredData.map((item) => item.id)]));
        }
    };

    const paginationClick = (nav, i) => {
        const params = {
            ...getParams(),
            page: i,
        };
        dispatch(clearJournalFilesData());
        dispatch(journalActions.getJournalFilesData(nav, params));
    };

    const filterMenuHandler = (tab) => {
        setCurrentFilter(tab);
        if (tab === 'all') {
            const params = {
                ...getParams(),
                state: states[tab],
            };
            dispatch(clearJournalFilesData());
            dispatch(journalActions.getJournalFilesData(navigate, params));
            selectOrders(new Set());
        }
        if (tab === 'inProgress') {
            const params = {
                ...getParams(),
                state: states[tab],
            };
            dispatch(clearJournalFilesData());
            dispatch(journalActions.getJournalFilesData(navigate, params));
            selectOrders(new Set());
        }
        if (tab === 'success') {
            const params = {
                ...getParams(),
                state: states[tab],
            };
            dispatch(clearJournalFilesData());
            dispatch(journalActions.getJournalFilesData(navigate, params));
            selectOrders(new Set());
        }
        if (tab === 'rejected') {
            const params = {
                ...getParams(),
                state: states[tab],
            };
            dispatch(clearJournalFilesData());
            dispatch(journalActions.getJournalFilesData(navigate, params));
            selectOrders(new Set());
        }
        if (tab === 'expired') {
            const params = {
                ...getParams(),
                state: states[tab],
            };
            dispatch(clearJournalFilesData());
            dispatch(journalActions.getJournalFilesData(navigate, params));
            selectOrders(new Set());
        }
    };

    const applyOKPOandIBANfilters = (nav) => {
        const params = getParams();
        dispatch(clearJournalFilesData());
        dispatch(journalActions.getJournalFilesData(nav, params));
        setKeyForPagination(keyForPagination + 1);
        selectOrders(new Set());
    };

    const maxDateRange = (startDate) => {
        const endDate = addDays(startDate, 29);
        if (endDate.getTime() > new Date().getTime()) {
            return new Date();
        }
        return endDate;
    };

    const CustomInput = forwardRef(({ value, onClick, ...rest }, ref) => (
        <button className={Styles.dateInput} onClick={onClick} ref={ref}>
            {value ? value : 'Операції за тиждень'}
        </button>
    ));

    return (
        <>
            <div className={Styles.buttonContainer}>
                <div onClick={() => filterMenuHandler('all')} className={currentFilter === 'all' ? Styles.active_tab : Styles.tab}>
                    Усі
                </div>
                <div onClick={() => filterMenuHandler('inProgress')} className={currentFilter === 'inProgress' ? Styles.active_tab : Styles.tab}>
                    В роботі
                </div>
                <div onClick={() => filterMenuHandler('success')} className={currentFilter === 'success' ? Styles.active_tab : Styles.tab}>
                    Успішні
                </div>
                <div onClick={() => filterMenuHandler('rejected')} className={currentFilter === 'rejected' ? Styles.active_tab : Styles.tab}>
                    Забраковані/Відкликані
                </div>
                <div onClick={() => filterMenuHandler('expired')} className={currentFilter === 'expired' ? Styles.active_tab : Styles.tab}>
                    Без відповіді
                </div>
            </div>
            <div className={Styles.picker_wrap}>
                <DatePicker
                    dateFormat={'yyyy-MM-dd'}
                    selectsRange={true}
                    maxDate={maxDateRange(startDate)}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        setDateRange(update);
                        if (!update[0] && !update[1]) {
                            const params = {
                                ...getParams(),
                                from: null,
                                to: null,
                                page: 0,
                            };
                            dispatch(journalActions.getJournalFilesData(navigate, params));
                            selectOrders(new Set());
                            setKeyForPagination(keyForPagination + 1);
                        }
                    }}
                    isClearable={true}
                    customInput={<CustomInput />}
                />
                <div className={Styles.okpo}>
                    <div className={Styles.filterContainer}>
                        <input onChange={(e) => setOKPO(e.target.value)} value={OKPO} type="text" placeholder={'ЄДРОУП/ІПН'} />
                        {OKPO ? (
                            <div onClick={() => setOKPO('')} className={Styles.clear}>
                                x
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className={Styles.iban}>
                    <div className={Styles.filterContainer}>
                        <input onChange={(e) => setIBAN(e.target.value)} value={IBAN} type="text" placeholder={'Номер рахунку'} />
                        {IBAN ? (
                            <div onClick={() => setIBAN('')} className={Styles.clear}>
                                x
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <Button onClick={applyOKPOandIBANfilters} class={Styles.filterSendButton} title={'Застосувати'} />
            <JournalItemsTableAllData
                journalFileData={journalFileData}
                selectedOrders={selectedOrders}
                selectOrder={selectOrder}
                selectAllOrder={selectAllOrder}
            />
            <Pagination key={currentFilter + keyForPagination} totalPages={journalFileData?.totalPages} action={paginationClick} />
            <DownloadFile extraClass={Styles.downloadButtonExtraClass} selectedOrders={selectedOrders} />
        </>
    );
};

export default JournalAllData;
