// Core
import { createSlice } from '@reduxjs/toolkit';

const journalSlice = createSlice({
    name: 'journalData',
    initialState: {
        journalFiles: null,
        journalFileData: null,
        openId: null,
        cancelOptions: null,
        fileLogs: null,
        removedFile: null,
    },
    reducers: {
        setJournalFiles: (state, action) => {
            state.journalFiles = action.payload;
        },
        clearJournalFiles: (state, action) => {
            state.journalFiles = null;
        },
        setJournalFileDate: (state, action) => {
            state.journalFileData = action.payload;
        },
        clearJournalFilesData: (state, action) => {
            state.journalFileData = null;
        },
        setCancelOptions: (state, action) => {
            state.cancelOptions = action.payload;
        },
        setFileLogs: (state, action) => {
            state.fileLogs = action.payload;
        },
        setSuccessRemovedFile: (state, action) => {
            state.removedFile = action.payload;
        },
        clearRemovedFile: (state, action) => {
            state.removedFile = null;
        },
    },
});

export const {
    setJournalFiles,
    setJournalFileDate,
    clearJournalFilesData,
    setCancelOptions,
    clearJournalFiles,
    setFileLogs,
    setSuccessRemovedFile,
    clearRemovedFile,
} = journalSlice.actions;

export default journalSlice.reducer;
