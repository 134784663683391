const paramsAPI = {
    getVariable: () => {
        return fetch(`/api/v1/params`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });
    },
};

export default paramsAPI;
