// Core
import { createSlice } from '@reduxjs/toolkit';

const fileSlice = createSlice({
    name: 'fileData',
    initialState: {
        dbfFileData: null,
        signedEcpData: null,
        signRequestState: null,
        signURL: null,
        stampURL: null,
        stateStamped: null,
        isFileUpdated: false,
    },
    reducers: {
        setDbfFileData: (state, action) => {
            state.dbfFileData = action.payload;
        },
        setSignedEcpData: (state, action) => {
            state.signedEcpData = action.payload;
        },
        clearFileData: (state, action) => {
            state.dbfFileData = null;
            state.signedEcpData = null;
        },
        setSignRequestState: (state, action) => {
            state.signRequestState = 'OK';
        },
        clearSignRequestState: (state, action) => {
            state.signRequestState = null;
        },
        saveQrCodeSignURL: (state, action) => {
            state.signURL = action.payload;
        },
        saveQrCodeStampURL: (state, action) => {
            state.stampURL = action.payload;
        },
        clearQrCodeSignURL: (state) => {
            state.signURL = null;
        },
        clearQrCodeStampURL: (state) => {
            state.stampURL = null;
        },
        setStateStamped: (state) => {
            state.stateStamped = true;
        },
        clearStateStamped: (state) => {
            state.stateStamped = null;
        },
        setUpdateFileData: (state, action) => {
            state.dbfFileData.savedRows = state.dbfFileData.savedRows.map((item) => {
                if (item.id === action.payload.id) {
                    return action.payload;
                }
                return item;
            });
        },
        setIsFileUpdated: (state, action) => {
            state.isFileUpdated = action.payload;
        },
    },
});

export const {
    setDbfFileData,
    setSignedEcpData,
    clearFileData,
    setSignRequestState,
    clearSignRequestState,
    saveQrCodeSignURL,
    saveQrCodeStampURL,
    clearQrCodeSignURL,
    clearQrCodeStampURL,
    setStateStamped,
    clearStateStamped,
    setUpdateFileData,
    setIsFileUpdated,
} = fileSlice.actions;

export default fileSlice.reducer;
