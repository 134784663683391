//API
import { loggerAPI, userAPI } from '../api';
import { setLoader, setAuthData } from '../reducers';
//Helpers
import { isValidJSON, errorResponseHelper } from '../helpers';

const userActions = {
    getUserDataByToken: (token, navigate) => async (dispatch) => {
        try {
            dispatch(setLoader(true));
            const response = await userAPI.getUserDataByToken(token);
            if (!response.ok) {
                await errorResponseHelper(response);
            }
            const userData = await response.json();
            dispatch(setAuthData(userData));
        } catch (e) {
            console.log(e);
            loggerAPI.log({
                logger: 'prexp24b',
                msg: e,
                level: 'INFO',
            });
            navigate('/error', {
                replace: true,
                state: {
                    text: isValidJSON(e?.message) ? JSON.parse(e.message) : null,
                    buttons: [
                        { path: '/', text: 'На головну' },
                        { path: '/journal/files', text: 'Журнал' },
                    ],
                },
            });
        } finally {
            dispatch(setLoader(false));
        }
    },
};

export default userActions;
