//Core
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
//Styles
import Styles from './styles.module.css';
//Actions
import { fileActions } from '../../actions';

const UploadFile = () => {
    const dispatch = useDispatch();
    const [fileName, setFileName] = useState(null);
    const navigate = useNavigate();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({ mode: 'onChange' });

    const onSubmit = (data) => {
        const file = data.file?.[0];
        const formData = new FormData();
        formData.append(`file`, file);
        dispatch(fileActions.uploadFileAction(formData, navigate));
        navigate('/sign');
    };

    return (
        <div className={Styles.wrapper}>
            <div className={Styles.title}>Примусове списання</div>
            <div className={Styles.doc}>Документи</div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <label>
                    <div className={Styles.uploadFile}>
                        <div className={Styles.uploadTitle}>
                            <div className={Styles.arrow}></div>
                            <div>{fileName ? fileName : 'Завантажити файл'}</div>
                        </div>
                    </div>
                    <input
                        className={Styles.hide}
                        type="file"
                        {...register('file', {
                            validate: (value) => {
                                const regExp = /^[a-zA-Z]{1}?[0-9]*\.(dbf)$/;
                                // const regExp = /(\.dbf)$/i;
                                const file = value?.[0];
                                if (regExp.exec(file?.name)) {
                                    setFileName(file?.name);
                                    return null;
                                }
                                setFileName(null);
                                return "Ім'я файлу не коректне";
                            },
                        })}
                    />
                </label>
                <div className={Styles.fileFormat}>
                    {errors?.file?.message ? <div className={Styles.fileErrorText}>{errors?.file?.message}</div> : 'Файл формату .dbf'}
                </div>
                <input type={'submit'} className={Styles.sendBtn} value={'Відправити'} />
            </form>
        </div>
    );
};

export default UploadFile;
