//Core
import React from 'react';
import { useSelector } from 'react-redux';
//Components
import { Button } from '../index';
//API
import { journalAPI, loggerAPI } from '../../api';
//Styles
import Styles from './styles.module.css';
import { errorResponseHelper } from '../../helpers';

const DownloadFile = ({ fileName, selectedOrders, extraClass }) => {
    const apiKey = useSelector((state) => state.authData.data?.apiKey);

    const downloadFile = async () => {
        try {
            console.log(selectedOrders);
            if (!fileName && !selectedOrders?.size) {
                return;
            }
            let response;
            if (fileName) {
                response = await journalAPI.downloadFile(fileName, apiKey);
            }
            if (selectedOrders?.size) {
                response = await journalAPI.downloadFileByOrders(selectedOrders, apiKey);
            }
            if (!response.ok) {
                await errorResponseHelper(response);
            }
            const contentDisposition = response.headers.get('Content-Disposition');
            if (contentDisposition) {
                const name = contentDisposition.split('=')[1];
                const blob = await response.blob();
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = name;
                a.click();
                a.remove();
            }
        } catch (e) {
            console.log(e);
            loggerAPI.log({
                logger: 'prexp24b',
                msg: e,
                level: 'INFO',
            });
        }
    };

    return (
        <>
            <Button onClick={downloadFile} class={`${Styles.btn} ${extraClass}`} title={'Вивантажити'} />
        </>
    );
};

export default DownloadFile;
