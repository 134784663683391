//Core
import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { QRCodeSVG } from 'qrcode.react';
//Styles
import Styles from './styles.module.css';
//Actions
import { fileActions } from '../../actions';

const QrSignPopUp = ({ QrUrl, fileName, closeAction, titleText }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const waitScanQrCodeInterval = useRef();

    useEffect(() => {
        waitScanQrCodeInterval.current = setInterval(() => {
            dispatch(
                fileActions.getSignFileState(
                    {
                        fileName,
                        waitScanQrCodeInterval: waitScanQrCodeInterval.current,
                    },
                    navigate
                )
            );
        }, 2000);

        dispatch(
            fileActions.getSignFileState(
                {
                    fileName,
                    waitScanQrCodeInterval: waitScanQrCodeInterval.current,
                },
                navigate
            )
        );

        return () => {
            clearInterval(waitScanQrCodeInterval.current);
            dispatch(closeAction[0]());
        };
    }, []);

    const closePopUp = () => {
        clearInterval(waitScanQrCodeInterval.current);
        dispatch(closeAction[0]());
        dispatch(closeAction[1]());
        dispatch(closeAction[2]());
    };

    return (
        <section>
            <div className={Styles.popUp}>
                <div className={Styles.closeContainer}>
                    <div className={Styles.close} onClick={closePopUp}>
                        X
                    </div>
                </div>
                <div className={Styles.text}>{titleText}</div>
                <div className={Styles.qrContainer}>
                    <QRCodeSVG value={QrUrl} size={250} />,
                </div>
            </div>
        </section>
    );
};

export default QrSignPopUp;
