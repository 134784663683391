//Core
import React from 'react';
import { NavLink } from 'react-router-dom';
//Styles
import Styles from './styles.module.css';

const NavLinkBtn = (props) => {
    return (
        <NavLink className={`${Styles.nav} ${props?.class}`} to={props.to}>
            {props.title}
        </NavLink>
    );
};

export default NavLinkBtn;
