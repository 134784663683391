//Core
import React from 'react';
import { NavLink, useHref, Outlet } from 'react-router-dom';
//Components
import { NavLinkBtn } from '../../components';
import Styles from './styles.module.css';

const JournalPage = () => {
    const href = useHref();

    return (
        <section className={Styles.container}>
            <div className={Styles.navContainer}>
                <div className={Styles.title}>Журнал</div>
                <NavLinkBtn to="/" title="На головну" />
            </div>
            <div className={Styles.buttonContainer}>
                <div className={`${Styles.item} ${href === '/journal/files' ? Styles.active : null}`}>
                    <NavLink className={Styles.nav} to={'/journal/files'}>
                        По файлам
                    </NavLink>
                </div>
                <div className={`${Styles.item} ${href === '/journal/all' ? Styles.active : null}`}>
                    <NavLink className={Styles.nav} to={'/journal/all'}>
                        Усі записи
                    </NavLink>
                </div>
            </div>
            <Outlet />
        </section>
    );
};

export default JournalPage;
