//Core
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//Styles
import Styles from './styles.module.css';
//Components
import { Button, FileTable, Loader, QrSignPopUp } from '../../components';
//Actions
import { fileActions } from '../../actions';
//Hooks
import { useSign } from '../../hooks';
//Reducers
import { clearQrCodeStampURL, clearQrCodeSignURL, clearStateStamped } from '../../reducers';
//Helpers
import { isRoleForSign } from '../../helpers';

const SignPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dbfFileData = useSelector((state) => state.file.dbfFileData);
    const stampURL = useSelector((state) => state.file.stampURL);
    const signURL = useSelector((state) => state.file.signURL);
    const stateStamped = useSelector((state) => state.file.stateStamped);
    const roles = useSelector((state) => state.authData.data?.roles);
    const loading = useSelector((state) => state.ui.loader);

    const fileName = dbfFileData?.savedRows?.[0]?.fileName;

    useSign(fileName);

    const signFn = () => {
        if (!isRoleForSign(roles?.[0]) || stampURL || signURL) {
            return;
        }
        if (fileName) {
            dispatch(fileActions.signRequestAction({ fileName }, navigate));
        }
    };

    return (
        <section className={Styles.container}>
            <Button class={Styles.btn} onClick={() => navigate('/')} title="Додати інструкцію" />
            {dbfFileData?.savedRows ? (
                <>
                    <FileTable dbfFileData={dbfFileData} />
                    {stampURL && fileName ? (
                        <QrSignPopUp
                            titleText={'Для накладання печатки відскануйте qr-код у мобільному додатку "Privat24-business"'}
                            key={stampURL}
                            fileName={fileName}
                            QrUrl={stampURL}
                            closeAction={[clearQrCodeStampURL, clearQrCodeSignURL, clearStateStamped]}
                        />
                    ) : loading ? (
                        <div className={Styles.qrLoader}>
                            <Loader />
                        </div>
                    ) : null}

                    {signURL && fileName && stateStamped ? (
                        <QrSignPopUp
                            titleText={'Для накладання підпису відскануйте qr-код у мобільному додатку "Privat24-business"'}
                            key={signURL}
                            fileName={fileName}
                            QrUrl={signURL}
                            closeAction={[clearQrCodeStampURL, clearQrCodeSignURL, clearStateStamped]}
                        />
                    ) : null}
                </>
            ) : (
                <Loader />
            )}

            {dbfFileData?.savedRows && isRoleForSign(roles?.[0]) ? <Button class={Styles.btn_sign} onClick={signFn} title="Підписати" /> : null}
        </section>
    );
};

export default SignPage;
