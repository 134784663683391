export const getFullDate = (date) => {
    const month = `${date.getMonth() + 1}`;
    const day = `${date.getDate()}`;
    return `${date.getFullYear()}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
};

export const getDate = (utcDate) => {
    try {
        const date = new Date(utcDate);
        return `${`${date.getDate()}`.padStart(2, '0')}.${`${date.getMonth() + 1}`.padStart(2, '0')}.${date.getFullYear()}`;
    } catch (e) {
        console.log(e);
    }
};

export const getTime = (utcDate) => {
    try {
        const date = new Date(utcDate);
        return `${date.getHours()}:${`${date.getMinutes()}`.padStart(2, '0')}`;
    } catch (e) {
        console.log(e);
    }
};

export const getFormattedDataAndTime = (UTCData) => {
    return `${getDate(UTCData)} ${getTime(UTCData)}`;
};

export const addDays = (startDate, countOfDays) => {
    const oneDayMs = 86400 * 1000;
    const date = startDate ? new Date(startDate) : new Date();
    return new Date(date.getTime() + countOfDays * oneDayMs);
};
