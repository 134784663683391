//Core
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components';
//Actions
import { fileActions } from '../../actions';
//Styles
import Styles from './styles.module.css';
import pencil from '../../theme/assets/icons/icon-pencil.png';
import { setIsFileUpdated } from '../../reducers';

const FileTable = ({ dbfFileData }) => {
    const savedRows = dbfFileData?.savedRows || [];
    const [editData, setEditData] = useState({});
    const [showPopUp, setShowPopup] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isFileUpdated = useSelector((state) => state.file.isFileUpdated);
    const roles = useSelector((state) => state.authData.data?.roles);

    useEffect(() => {
        if (isFileUpdated) {
            setEditData({});
            setShowPopup(false);
            dispatch(setIsFileUpdated(false));
        }
    }, [isFileUpdated]);

    const editPurposeFn = (purpose, id, version) => () => {
        if (!roles?.[1] || roles?.[1] !== 'EDITOR') {
            return;
        }
        setEditData({
            purpose,
            id,
            version,
        });
        setShowPopup(true);
    };
    return (
        <div className={Styles.table}>
            <div className={Styles.table_item}>
                <span className={Styles.docNum}>Номер документу</span>
                <span className={Styles.sum}>Сума платежу</span>
                <span className={Styles.debtorAccount}>Номер рахунку боржника</span>
                <span className={Styles.debtorName}>Найменування боржника</span>
                <span className={Styles.executorOkpo}>ЄДРОУП/ІПН боржника</span>
                <span className={Styles.purpose}>Призначення платежу</span>
            </div>
            {showPopUp ? (
                <>
                    <div className={Styles.popUp}>
                        <textarea
                            className={Styles.editText}
                            value={editData.purpose}
                            onChange={(e) => {
                                if (e.target.value?.length > 500) {
                                    return;
                                }
                                setEditData({
                                    ...editData,
                                    purpose: e.target.value,
                                });
                            }}
                        />
                        <div className={Styles.counter}>{editData.purpose.length} з 500 символів</div>
                        <div className={Styles.popUpButtonContainer}>
                            <Button
                                onClick={() => {
                                    setEditData({});
                                    setShowPopup(false);
                                }}
                                class={Styles.cancelBtn}
                                title={'Скасувати'}
                            />
                            <Button
                                onClick={() => {
                                    dispatch(fileActions.upDateFileAction(editData, navigate));
                                }}
                                title={'Зберегти'}
                            />
                        </div>
                    </div>
                </>
            ) : null}
            {savedRows.map((item) => {
                return (
                    <div key={item.id} className={Styles.table_item}>
                        <span className={Styles.docNum}>{item.docNum}</span>
                        <span className={Styles.sum}>{item.sum}</span>
                        <span className={Styles.debtorAccount}>{item.debtorAccount}</span>
                        <span className={Styles.debtorName}>{item.debtorName}</span>
                        <span className={Styles.executorOkpo}>{item.debtorOkpo}</span>
                        <span className={Styles.purpose}>
                            {item.purpose}
                            {roles?.[1] === 'EDITOR' ? (
                                <img
                                    onClick={editPurposeFn(item.purpose, item.id, item.version)}
                                    className={Styles.pencil}
                                    src={pencil}
                                    alt="pencil"
                                />
                            ) : null}
                        </span>
                    </div>
                );
            })}
        </div>
    );
};

export default FileTable;
