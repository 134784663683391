//Core
import React, { useEffect, useRef } from 'react';
//Styles
import Styles from './styles.module.css';
import actionIcon from '../../theme/assets/icons/more_icon.svg';
import { setCancelOptions } from '../../reducers';
import { useDispatch, useSelector } from 'react-redux';
//Helpers
import { isRoleForSign } from '../../helpers';

const states = {
    all: [],
    inProgress: ['NOT_SIGNED', 'SIGNED', 'SENT', 'SENT_CUST_BANK'],
    success: ['SUCCESS'],
    rejected: ['CANCELED', 'CANCEL_CUST_BANK', 'DECLINE', 'ERROR'],
};

const JournalItemAction = ({ item, fileName, setOpenId, openId }) => {
    const dispatch = useDispatch();
    const roles = useSelector((state) => state.authData.data?.roles);
    const env = useSelector((state) => state.authData.params);
    const refID = useRef();

    const handleClick = () => {
        const ID = refID.current;
        if (ID) {
            setOpenId(null);
            refID.current = null;
        }
    };

    useEffect(() => {
        document.addEventListener('click', () => handleClick());

        return () => {
            document.removeEventListener('click', () => handleClick());
        };
    }, []);

    const onToggle = (e, id) => {
        e.stopPropagation();
        if (!isRoleForSign(roles?.[0])) {
            return;
        }
        const currentID = openId === id ? null : id;
        setOpenId(currentID);
        refID.current = currentID;
    };

    const setDropdownClass = (id) => {
        return openId !== id ? Styles.dropdown_hide : Styles.dropdown_open;
    };

    const cancelHandler = (e, { id, state, signForHash }) => {
        e.stopPropagation();
        setOpenId(null);
        refID.current = null;
        dispatch(setCancelOptions({ fileName, id }));
        signFn(id, signForHash);
    };

    const signFn = (id, signForHash) => {
        window.parent.postMessage(
            JSON.stringify({
                action: 'ecpSign',
                data: [
                    {
                        id: id,
                        hash: signForHash,
                    },
                ],
            }),
            env.REACT_APP_P24URL
        );
    };

    const showCancelButton = (item) => {
        return states.inProgress.includes(item.state);
    };

    const successGoTo = (state = '') => {
        if (state === 'SUCCESS') {
            window.parent.postMessage(
                {
                    action: 'goTo',
                    id: 23,
                },
                env.REACT_APP_P24URL
            );
        }
    };

    return isRoleForSign(roles?.[0]) ? (
        <>
            <img onClick={(e) => onToggle(e, item.id)} className={Styles.action_icon} src={actionIcon} alt="more_icon" />
            {showCancelButton(item) ? (
                <div className={setDropdownClass(item.id)} onClick={(e) => cancelHandler(e, item)}>
                    Відкликати
                </div>
            ) : null}
            {item.state === 'SUCCESS' ? (
                <div data-id="statement" className={`${setDropdownClass(item.id)}`} onClick={(e) => successGoTo(item?.state)}>
                    Виписка
                </div>
            ) : null}
            {/*{item.state === 'SUCCESS' ? (*/}
            {/*    <div className={`${setDropdownClass(item.id)} ${Styles.signString} `} onClick={(e) => signFn(item.id, item.signForHash)}>*/}
            {/*        Підписати*/}
            {/*    </div>*/}
            {/*) : null}*/}
        </>
    ) : null;
};

export default JournalItemAction;
