//Core
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
//Styles
import Styles from './styles.module.css';
//Actions
import { journalActions } from '../../actions';
//Helpers
import { getDate, getTime } from '../../helpers';
//Images
import pdfIcon from '../../theme/assets/icons/pdf_download_icon.png';
//API
import { journalAPI } from '../../api';

const DetailedInformationPopUp = ({ titleText, setDetailsPopUpIsOpen, data }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isOpen, setToggle] = useState(false);
    const [animation, setAnimation] = useState(false);
    const logs = useSelector((state) => state.journalData.fileLogs);
    const apiKey = useSelector((state) => state.authData.data?.apiKey);

    useEffect(() => {
        dispatch(journalActions.getLogs(data.id, navigate));
    }, []);

    const closePopUp = () => {
        setDetailsPopUpIsOpen(false);
    };

    const toggleDetails = () => {
        setAnimation(!animation);
        if (!isOpen) {
            setToggle(true);
            return;
        }
        setTimeout(() => setToggle(!isOpen), 170);
    };

    const getFormatedState = (state) => {
        switch (state) {
            case 'NOT_SIGNED':
                return 'Збережений';
            case 'SIGNED':
                return 'Підписаний';
            case 'SENT':
                return 'Направлено в роботу';
            case 'SENT_CUST_BANK':
                return 'Направлено в банк боржника';
            case 'CANCELED':
                return 'Відкликано';
            case 'CANCEL_CUST_BANK':
                return 'Надіслано відклик до банку боржника';
            case 'DECLINE':
            case 'ERROR':
                return 'Сталася помилка при обробці запиту';
            case 'SUCCESS':
                return 'Опрацьовано';
            default:
                return '';
        }
    };

    const getFormatedRole = (role) => {
        switch (role) {
            case 'PRIVAT_OWNER':
                return 'Приватним виконавцем';
                break;
            case 'PRIVAT_ACCOUNTANT':
            case 'STATE_ASSISTANT':
                return 'Помічником';
                break;
            case 'STATE_OWNER':
                return 'Директором';
                break;
            case 'STATE_ACCOUNTANT':
                return 'Бухгалтером';
                break;
            case 'PROCESSING':
                return 'ПриватБанком';
                break;
            case 'UNKNOWN':
                return '';
                break;
            default:
                return '';
        }
    };

    const showWhoChange = (state) => {
        if (state === 'NOT_SIGNED' || state === 'SIGNED' || state === 'CANCELED') {
            return true;
        }
        return false;
    };

    const getLogsFormatedText = (log) => {
        const formatedState = getFormatedState(log?.state);
        const formatedRole = getFormatedRole(log?.changedBy);
        return `${getDate(log?.createAt)} - ${getTime(log?.createAt)} - ${formatedState} ${showWhoChange(log?.state) ? formatedRole : ''}`;
    };

    const downloadPdf = (id) => async () => {
        try {
            const response = await journalAPI.downloadPdfFile(id, apiKey);

            if (!response.ok) {
                const text = await response.json();
                const errorMessage = {
                    code: response.status,
                    statusText: response.statusText,
                    message: text,
                };
                throw new Error(JSON.stringify(errorMessage));
            }
            const name = `file_${id}`;
            const blob = await response.blob();
            let url = window.URL.createObjectURL(blob);
            let a = document.createElement('a');
            a.href = url;
            a.download = name;
            a.click();
            a.remove();
        } catch (e) {
            console.log(e);
        }
    };

    return (
        <div
            className={Styles.popUpContainer}
            onClick={(e) => {
                if (e.target === e.currentTarget) {
                    setDetailsPopUpIsOpen(false);
                }
            }}
        >
            <section>
                <div className={Styles.popUp}>
                    <div className={Styles.closeContainer}>
                        <img className={Styles.downloadPdfButton} src={pdfIcon} onClick={downloadPdf(data.id)} alt="Download" />
                        <div className={Styles.close} onClick={closePopUp}>
                            X
                        </div>
                    </div>
                    <div className={Styles.text}>{titleText}</div>
                    <div className={Styles.qrContainer}>
                        <div>
                            <div className={Styles.title}>
                                Платіжна інструкція на примусове списання коштів <span className={Styles.titleNumber}>№{data.docNum}</span>
                                <span className={Styles.titleData}>{getDate(data?.createAt)}</span>
                            </div>
                            <div className={`${Styles.containerLogs} ${animation ? Styles.animation : ''}`}>
                                {logs && logs?.length ? (
                                    <>
                                        {!isOpen ? (
                                            <div className={Styles.orderDetailsContainer}>
                                                <div onClick={toggleDetails} className={Styles.orderDetails}>
                                                    <div className={Styles.circle} />
                                                    {getLogsFormatedText(logs[0])}
                                                </div>
                                                <div>
                                                    <div
                                                        onClick={toggleDetails}
                                                        className={`${Styles.checkMark} ${isOpen ? `${Styles.rotate}` : ``}`}
                                                    >
                                                        &#9660;
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <>
                                                <div className={Styles.orderDetailsContainer}>
                                                    <div>
                                                        <div onClick={toggleDetails} className={Styles.detailsFullList}>
                                                            {logs.map((log) => {
                                                                return (
                                                                    <div className={Styles.orderDetails}>
                                                                        <div className={Styles.circle} />
                                                                        {getLogsFormatedText(log)}
                                                                    </div>
                                                                );
                                                            })}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div
                                                            onClick={toggleDetails}
                                                            className={`${Styles.checkMark} ${isOpen ? `${Styles.rotate}` : ``}`}
                                                        >
                                                            &#9660;
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </>
                                ) : null}
                            </div>
                        </div>
                        <div className={Styles.containerBorder}>
                            <div className={Styles.sumContainer}>
                                <div>Сума стягнення:</div>
                                <div className={Styles.sum}>
                                    {data.sum} <span>UAH</span>
                                </div>
                            </div>
                            <div>
                                <div>Результат обробки:</div>
                                <div className={Styles.result}>
                                    {/*SUCCESS*/}
                                    {data.state === 'SUCCESS' && !data.factSum ? (
                                        <>
                                            <div className={Styles.factsum}>
                                                <div>Виконано в повному обсязі - </div>
                                                <div className={Styles.data}>{getDate(data?.modifiedAt)}</div>
                                            </div>
                                        </>
                                    ) : null}

                                    {data.state === 'SUCCESS' && data.factSum && data.factSum === data.sum ? (
                                        <>
                                            <div className={Styles.factsum}>
                                                <div>Виконано в повному обсязі - </div>
                                                <div className={Styles.data}>{getDate(data?.modifiedAt)}</div>
                                            </div>
                                        </>
                                    ) : null}
                                    {/*******/}

                                    {/*SUCCESS AND FACTSUM*/}
                                    {data.state === 'SUCCESS' && data.factSum && data.factSum < data.sum ? (
                                        <>
                                            <div className={Styles.factsum}>
                                                <div>Виконано частково - </div>
                                                <div className={Styles.data}>{getDate(data?.modifiedAt)}</div>
                                            </div>
                                            <div className={Styles.sum}>
                                                {data.factSum} <span>UAH</span>
                                            </div>
                                        </>
                                    ) : null}
                                    {/*******/}

                                    {/*******/}
                                    {/*ERROR*/}
                                    {data.state === 'CANCELED' ||
                                    data.state === 'CANCEL_CUST_BANK' ||
                                    data.state === 'DECLINE' ||
                                    data.state === 'ERROR' ? (
                                        <>
                                            <div className={Styles.errorContainer}>
                                                <div>Відмова - </div>
                                                <div className={Styles.data}>{getDate(data?.modifiedAt)}</div>
                                                <div>{data.errorReason}</div>
                                            </div>
                                        </>
                                    ) : null}
                                    {/*******/}
                                </div>
                            </div>
                            <div className={Styles.destination}>
                                <div>Призначення платежу</div>
                                <div className={Styles.destinationText}>{data.purpose}</div>
                            </div>
                            <div className={Styles.mainContainer}>
                                <div className={Styles.leftContainer}>
                                    <div className={Styles.bold}>Платник</div>
                                    <div className={Styles.bold}>{data.debtorName}</div>
                                    <div className={Styles.iban}>
                                        <div>Рахунок платника</div>
                                        <div>{data.debtorAccount}</div>
                                    </div>
                                    <div className={Styles.okpo}>
                                        <div>Код платника</div>
                                        <div>{data.debtorOkpo}</div>
                                    </div>
                                    <div className={Styles.who}>
                                        <div className={Styles.d}>Надавач платіжних послуг платника</div>
                                        <div>{data?.debtorBank ? data.debtorBank : '---'}</div>
                                    </div>
                                </div>
                                <div className={Styles.rightContainer}>
                                    <div className={Styles.bold}>Отримувач</div>
                                    <div className={Styles.bold}>{data.executorName}</div>
                                    <div className={Styles.iban}>
                                        <div>Рахунок отримувача</div>
                                        <div>{data.executorAccount}</div>
                                    </div>
                                    <div className={Styles.okpo}>
                                        <div>Код отримувача</div>
                                        <div>{data.executorOkpo}</div>
                                    </div>
                                    <div className={Styles.who}>
                                        <div className={Styles.e}>Надавач платіжних послуг отримувача</div>
                                        <div>АТ КБ "ПРИВАТБАНК"</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*<div>Платіжна інструкція на примусове списання коштів номер дока</div>*/}
                        {/*<div>{data.id}</div>*/}
                        {/*<div>{data.docNum}</div>*/}
                        {/*<div>{data.sum}</div>*/}
                        {/*<div>{data.factSum}</div>*/}
                        {/*<div>{data.modifiedAt}</div>*/}
                        {/*<div>{data.errorReason}</div>*/}
                        {/*<div>{data.purpose}</div>*/}
                        {/*<div>{data.debtorName}</div>*/}
                        {/*<div>{data.debtorAccount}</div>*/}
                        {/*<div>{console.log(data)}</div>*/}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default DetailedInformationPopUp;
