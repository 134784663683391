import { configureStore } from '@reduxjs/toolkit';
import { fileReducer, uiReducer, authSlice, journalSlice } from './reducers';

export const store = configureStore({
    reducer: {
        file: fileReducer,
        ui: uiReducer,
        authData: authSlice,
        journalData: journalSlice,
    },
});
