//API
import { loggerAPI, paramsAPI } from '../api';
import { setLoader, setVariable } from '../reducers';
//Helpers
import { isValidJSON, errorResponseHelper } from '../helpers';

const paramsActions = {
    getVariable: (navigate) => async (dispatch) => {
        try {
            dispatch(setLoader(true));
            const response = await paramsAPI.getVariable();
            if (!response.ok) {
                await errorResponseHelper(response);
            }
            const data = await response.json();
            dispatch(setVariable(data));
        } catch (e) {
            console.log(e);
            loggerAPI.log({
                logger: 'prexp24b',
                msg: e,
                level: 'INFO',
            });
            navigate('/error', {
                replace: true,
                state: {
                    text: isValidJSON(e?.message) ? JSON.parse(e.message) : null,
                    buttons: [
                        { path: '/', text: 'На головну' },
                        { path: '/journal/files', text: 'Журнал' },
                    ],
                },
            });
        } finally {
            dispatch(setLoader(false));
        }
    },
};

export default paramsActions;
