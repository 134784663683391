//Core
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Routes, Route, useNavigate } from 'react-router-dom';
//Pages
import { UploadPage, SignPage, JournalPage, SuccessPage, ErrorPage, JournalItemPageByFile } from './pages';
//Actions
import { userActions, fileActions, journalActions } from './actions';
import { setSignedEcpData, setCancelOptions } from './reducers';
//Components
import { JournalAllData, JournalFiles } from './components';

const App = () => {
    const AllRoles = process.env.REACT_APP_ALL_ROLES.split(',');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const cancelOptions = useSelector((state) => state.journalData.cancelOptions);
    const roles = useSelector((state) => state.authData.data?.roles);
    const env = useSelector((state) => state.authData.params);

    const handleMessage = (event) => {
        try {
            if (event.origin !== env.REACT_APP_P24URL) {
                return;
            }
            const data = JSON.parse(event.data);
            if (data?.action === 'sendInitData') {
                window.parent.postMessage(
                    {
                        action: 'removePreload',
                    },
                    env.REACT_APP_P24URL
                );
                dispatch(userActions.getUserDataByToken(data?.authToken, navigate));
            }
            if (data?.action === 'signedEcpData') {
                window.parent.postMessage(
                    {
                        action: 'closeEcpSign',
                    },
                    env.REACT_APP_P24URL
                );

                const ecpData = data?.data
                    ? data?.data.map((item) => {
                          return {
                              id: item.id,
                              signHash: item.sign,
                          };
                      })
                    : null;
                if (ecpData && !cancelOptions) {
                    dispatch(setSignedEcpData(ecpData));
                    dispatch(fileActions.saveSignedEcpData(ecpData, navigate));
                }
                if (ecpData && cancelOptions) {
                    dispatch(journalActions.cancelPayment(cancelOptions?.fileName, cancelOptions?.id, navigate));
                    dispatch(setCancelOptions(null));
                }
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, [cancelOptions]);

    useEffect(() => {
        if (roles) {
            const role = roles[0];
            if (role === 'USER') {
                navigate('/error', {
                    replace: true,
                    state: {
                        buttons: [],
                        text: {
                            message: {
                                type: 'NoAccess',
                            },
                        },
                    },
                });
            }
        }
    }, [roles]);

    useEffect(() => {
        window.parent.postMessage(
            {
                action: 'getInitData',
                systemId: env?.REACT_APP_P24ID,
            },
            env.REACT_APP_P24URL
        );
    }, []);

    const checkRole = (role) => {
        if (!role || role === 'USER') {
            return false;
        }
        return AllRoles.some((roleItem) => role === roleItem);
    };

    return (
        <>
            {roles && checkRole(roles?.[0]) && env.REACT_APP_P24URL ? (
                <Routes>
                    <Route path={'/'} element={<UploadPage />} />
                    <Route path={'/journal'} element={<JournalPage />}>
                        <Route path={'files'} element={<JournalFiles />} />
                        <Route path={'all'} element={<JournalAllData />} />
                    </Route>
                    <Route path={'/journal/:fileName/:tab?'} element={<JournalItemPageByFile />} />
                    <Route path={'/sign'} element={<SignPage />} />
                    <Route path={'/success'} element={<SuccessPage />} />
                    <Route path={'/error'} element={<ErrorPage />} />
                </Routes>
            ) : (
                <Routes>
                    <Route path={'/error'} element={<ErrorPage />} />
                </Routes>
            )}
        </>
    );
};

export default App;
