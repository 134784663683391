//Core
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
//Styles
import Styles from './styles.module.css';
//Components
import { JournalItemStatus, JournalItemAction } from '../../components';
//Helpers
import { getFormattedDataAndTime } from '../../helpers';

const states = {
    all: [],
    inProgress: ['NOT_SIGNED', 'SIGNED', 'SENT', 'SENT_CUST_BANK'],
    success: ['SUCCESS'],
    rejected: ['CANCELED', 'CANCEL_CUST_BANK', 'DECLINE', 'ERROR'],
};

const JournalItemAllData = ({ item, selectOrder, selectedOrders, setOpenId, openId, setDetailsPopUpIsOpen, setDataFromPopUp }) => {
    const env = useSelector((state) => state.authData.params);

    const setResult = ({ state, factSum, sum, errorReason }) => {
        if (state === 'SUCCESS' && factSum) {
            return factSum;
        }
        if (state === 'SUCCESS' && !factSum) {
            return sum;
        }
        if (state === 'DECLINE' || state === 'ERROR') {
            return typeof errorReason === 'string' && errorReason.length > 20 ? `${`${errorReason}`.slice(0, 20)}...` : errorReason;
        }
        return '-';
    };

    const setResultClass = (item) => {
        return item.errorReason ? Styles.result : Styles.result_null;
    };

    // const successGoTo = (item) => {
    //     if (item.state === 'SUCCESS') {
    //         window.parent.postMessage(
    //             {
    //                 action: 'goTo',
    //                 id: 23,
    //             },
    //             env.REACT_APP_P24URL
    //         );
    //     }
    // };

    return (
        <>
            <tr
                className={Styles.pointer}
                onClick={(e) => {
                    if (
                        e.nativeEvent.target.tagName === 'LABEL' ||
                        e.nativeEvent.target.tagName === 'INPUT' ||
                        e.nativeEvent.target.dataset.id === 'statement'
                    ) {
                        return;
                    }
                    setDetailsPopUpIsOpen(true);
                    setDataFromPopUp(item);
                }}
                key={item.id}
            >
                <td>
                    <span className={Styles.checkbox}>
                        <input
                            disabled={states.inProgress.includes(item.state)}
                            checked={selectedOrders.has(item.id)}
                            type="checkbox"
                            id={item.id}
                            name={item.id}
                            className={Styles.input_checkbox}
                            onChange={(event) => selectOrder(item.id)}
                        />
                        <label className={Styles.label_checkbox} htmlFor={item.id} />
                    </span>
                </td>
                <td>
                    <span className={Styles.debtorAccount} title={item.debtorAccount}>
                        {item.debtorAccount ? `${item.debtorAccount.slice(0, 10)}...${item.debtorAccount.slice(-4)}` : null}
                    </span>
                </td>
                <td className={Styles.sumContainer}>
                    <span className={Styles.sum}>{item.sum}</span>
                </td>
                <td>
                    <span className={Styles.purpose} title={item.purpose}>
                        {item.purpose && item.purpose.length > 20 ? `${item.purpose.slice(0, 20)}...` : item.purpose}
                    </span>
                </td>
                <td>
                    <span className={Styles.executorOkpo}>{item.debtorOkpo}</span>
                </td>
                <td>
                    <span>{item.debtorName}</span>
                </td>
                <td>
                    <span className={setResultClass(item)} title={item.errorReason ? item.errorReason : null}>
                        {setResult(item)}
                    </span>
                </td>
                <td>
                    <span>{getFormattedDataAndTime(item.modifiedAt)}</span>
                </td>
                <td>
                    <span className={`${Styles.state} ${item.state === 'SUCCESS' ? Styles.stateActive : ''}`}>
                        <JournalItemStatus item={item} />
                    </span>
                </td>
                <td>
                    {' '}
                    <span className={Styles.action}>
                        <JournalItemAction setOpenId={setOpenId} openId={openId} item={item} />
                    </span>
                </td>
            </tr>
        </>
    );
};

export default JournalItemAllData;
