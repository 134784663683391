//API
import { journalAPI, loggerAPI } from '../api';
//Actions
import { setLoader, setJournalFiles, setJournalFileDate, clearFileData, setFileLogs, setSuccessRemovedFile } from '../reducers';
//Helpers
import { isValidJSON, errorResponseHelper } from '../helpers';

const journalActions = {
    getJournalFiles: (navigate, params) => async (dispatch, getState) => {
        try {
            const state = getState();
            const apiKey = state.authData.data?.apiKey;
            dispatch(setLoader(true));
            const response = await journalAPI.getJournalFiles(apiKey, params);

            if (!response.ok) {
                await errorResponseHelper(response);
            }

            const data = await response.json();
            if (data) {
                dispatch(setJournalFiles(data));
            }
        } catch (e) {
            console.log(e);
            loggerAPI.log({
                logger: 'prexp24b',
                msg: e,
                level: 'INFO',
            });
            navigate('/error', {
                replace: true,
                state: {
                    text: isValidJSON(e?.message) ? JSON.parse(e.message) : null,
                    buttons: [
                        { path: '/', text: 'На головну' },
                        { path: '/journal/files', text: 'Журнал' },
                    ],
                },
            });
        } finally {
            dispatch(setLoader(false));
        }
    },
    getJournalFilesData: (navigate, params) => async (dispatch, getState) => {
        try {
            const state = getState();
            const apiKey = state.authData.data?.apiKey;
            dispatch(setLoader(true));
            const response = await journalAPI.getJournalFileData(apiKey, params);

            if (!response.ok) {
                await errorResponseHelper(response);
            }

            const data = await response.json();
            if (data) {
                dispatch(setJournalFileDate(data));
            }
        } catch (e) {
            console.log(e);
            loggerAPI.log({
                logger: 'prexp24b',
                msg: e,
                level: 'INFO',
            });
            navigate('/error', {
                replace: true,
                state: {
                    text: isValidJSON(e?.message) ? JSON.parse(e.message) : null,
                    buttons: [
                        { path: '/', text: 'На головну' },
                        { path: '/journal/files', text: 'Журнал' },
                    ],
                },
            });
        } finally {
            dispatch(setLoader(false));
        }
    },
    cancelPayment:
        (fileName = null, id, navigate) =>
        async (dispatch, getState) => {
            try {
                const state = getState();
                const apiKey = state.authData.data?.apiKey;
                dispatch(setLoader(true));
                const response = await journalAPI.cancelPayment(id, apiKey);

                if (!response.ok) {
                    await errorResponseHelper(response);
                }

                if (response.status === 204) {
                    navigate('/success', {
                        state: {
                            buttons: [
                                { path: '/', text: 'На головну' },
                                fileName ? { path: `/journal/${fileName}`, text: 'Назад' } : { path: `/journal/all`, text: 'Назад' },
                            ],
                        },
                    });
                }
            } catch (e) {
                console.log(e);
                loggerAPI.log({
                    logger: 'prexp24b',
                    msg: e,
                    level: 'INFO',
                });
                navigate('/error', {
                    state: {
                        text: isValidJSON(e?.message) ? JSON.parse(e.message) : null,
                        buttons: [
                            { path: '/', text: 'На головну' },
                            fileName ? { path: `/journal/${fileName}`, text: 'Назад' } : { path: `/journal/all`, text: 'Назад' },
                        ],
                    },
                });
            } finally {
                dispatch(setLoader(false));
            }
        },
    getLogs: (id, navigate) => async (dispatch, getState) => {
        try {
            const state = getState();
            const apiKey = state.authData.data?.apiKey;
            dispatch(setLoader(true));
            const response = await journalAPI.getLogs(id, apiKey);

            if (!response.ok) {
                await errorResponseHelper(response);
            }

            const data = await response.json();
            if (data) {
                dispatch(setFileLogs(data));
            }
        } catch (e) {
            console.log(e);
            loggerAPI.log({
                logger: 'prexp24b',
                msg: e,
                level: 'INFO',
            });
            // navigate('/error', {
            //     replace: true,
            //     state: {
            //         text: isValidJSON(e?.message) ? JSON.parse(e.message) : null,
            //         buttons: [
            //             { path: '/', text: 'На головну' },
            //             { path: '/journal/files', text: 'Журнал' },
            //         ],
            //     },
            // });
        } finally {
            dispatch(setLoader(false));
        }
    },
    removeFileAction: (fileName, navigate) => async (dispatch, getState) => {
        try {
            const state = getState();
            const apiKey = state.authData.data?.apiKey;
            dispatch(setLoader(true));
            const response = await journalAPI.removeFile(fileName, apiKey);

            if (!response.ok) {
                await errorResponseHelper(response);
            }

            if (response.status === 204) {
                dispatch(setSuccessRemovedFile(fileName));
                // dispatch(journalActions.getJournalFiles(navigate, params));
                // setKeyForPagination(keyForPagination + 1);
                // navigate('/success', {
                //     state: {
                //         buttons: [
                //             { path: '/', text: 'На головну' },
                //             { path: `/journal/files`, text: 'Назад' },
                //         ],
                //     },
                // });
            }
        } catch (e) {
            console.log(e);
            loggerAPI.log({
                logger: 'prexp24b',
                msg: e,
                level: 'INFO',
            });
            navigate('/error', {
                state: {
                    text: isValidJSON(e?.message) ? JSON.parse(e.message) : null,
                    buttons: [
                        { path: '/', text: 'На головну' },
                        fileName ? { path: `/journal/${fileName}`, text: 'Назад' } : { path: `/journal/all`, text: 'Назад' },
                    ],
                },
            });
        } finally {
            dispatch(setLoader(false));
        }
    },
};

export default journalActions;
