export const errorResponseHelper = async (response) => {
    let text = '';
    try {
        text = await response.json();
    } catch (e) {}
    const errorMessage = {
        code: response.status,
        statusText: response.statusText,
        url: response.url,
        message: text,
    };
    throw JSON.stringify(errorMessage);
};
