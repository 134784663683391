//Core
import React from 'react';
//Styles
import Styles from './styles.module.css';

const JournalItemStatus = ({ item }) => {
    const setStatus = (status) => {
        switch (status) {
            case 'SIGNED':
            case 'SENT':
                return 'В роботі';
            case 'SENT_CUST_BANK':
                return 'Надіслано до банку клієнта';
            case 'NOT_SIGNED':
                return 'Не підписано';
            case 'CANCELED':
                return 'Відкликано';
            case 'DECLINE':
            case 'ERROR':
                return 'Відмовлено';
            case 'SUCCESS':
                return 'Успішно';
            case 'CANCEL_CUST_BANK':
                return 'Відклик надіслано';
            default:
                return '';
        }
    };

    const statusClass = (status) => {
        switch (status) {
            case 'SIGNED':
            case 'SENT':
            case 'SENT_CUST_BANK':
                return `${Styles.status} ${Styles.status_inwork}`;
            case 'NOT_SIGNED':
                return `${Styles.status} ${Styles.status_notSign}`;
            case 'CANCELED':
            case 'CANCEL_CUST_BANK':
                return `${Styles.status} ${Styles.status_blocked}`;
            case 'DECLINE':
            case 'ERROR':
                return `${Styles.status} ${Styles.status_blocked}`;
            case 'SUCCESS':
                return `${Styles.status} ${Styles.status_success}`;
            default:
                return Styles.status;
        }
    };

    return (
        <span className={statusClass(item.state)}>
            {/*To Do complete this from back end*/}
            {item.state === 'SUCCESS' && item.factSum && item.factSum < item.sum ? 'Виконано частково' : null}
            {item.state === 'SUCCESS' && item.factSum && item.factSum === item.sum ? 'Виконано повністю' : null}
            {item.state === 'SUCCESS' && !item.factSum ? 'Виконано повністю' : null}

            {item.state !== 'SUCCESS' ? setStatus(item.state) : null}
            {item.executionDate && (item.state === 'SIGNED' || item.state === 'SENT') ? <div>{`з ${item.executionDate}`}</div> : null}
        </span>
    );
};

export default JournalItemStatus;
