//Core
import React, { useState } from 'react';
//Styles
import Styles from './styles.module.css';
//Components
import { DetailedInformationPopUp, JournalItemAllData, Loader } from '../../components';
//Hooks
import { useDetailsPopUp } from '../../hooks';

const states = {
    all: [],
    inProgress: ['NOT_SIGNED', 'SIGNED', 'SENT', 'SENT_CUST_BANK'],
    success: ['SUCCESS'],
    rejected: ['CANCELED', 'CANCEL_CUST_BANK', 'DECLINE', 'ERROR'],
};

const JournalItemsTableAllData = ({ journalFileData, selectOrder, selectedOrders, selectAllOrder }) => {
    const [openId, setOpenId] = useState(null);
    const [isOpen, setDetailsPopUpIsOpen, data, setDataFromPopUp] = useDetailsPopUp();
    const handleInputChange = () => {
        selectAllOrder(isCheckedAll());
    };

    const isCheckedAll = () => {
        const fileData = journalFileData?.content?.filter((item) => !states.inProgress.includes(item.state)) || [];
        if (!fileData.length) {
            return false;
        }
        const selected = [...selectedOrders];

        return fileData.every((item) => selected.includes(item.id));
    };

    return (
        <>
            <table className={Styles.table}>
                <thead>
                    <tr>
                        <th>
                            <span className={Styles.checkbox}>
                                <input
                                    checked={isCheckedAll()}
                                    type="checkbox"
                                    id="all"
                                    name="all"
                                    className={Styles.input_checkbox}
                                    onChange={handleInputChange}
                                />
                                <label className={Styles.label_checkbox} htmlFor="all" />
                            </span>
                        </th>
                        <th>
                            <span className={Styles.debtorAccount}>Номер рахунку боржника</span>
                        </th>
                        <th>
                            <span className={Styles.sum}>Сума платежу</span>
                        </th>
                        <th>
                            <span className={Styles.purpose}>Призначення платежу</span>
                        </th>
                        <th>
                            <span className={Styles.executorOkpo}>ЄДРОУП/ІПН боржника</span>
                        </th>
                        <th>
                            <span className={Styles.debtorName}>Найменування платника</span>
                        </th>
                        <th>
                            <span className={Styles.result}>Результат обробки</span>
                        </th>
                        <th>
                            <span className={Styles.modifiedAt}>Дата опрацювання</span>
                        </th>
                        <th>
                            <span className={Styles.state}>Статус</span>
                        </th>
                        <th>
                            <span className={Styles.action}></span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {journalFileData?.content ? (
                        journalFileData.content.map((item) => {
                            return (
                                <JournalItemAllData
                                    setOpenId={setOpenId}
                                    openId={openId}
                                    selectedOrders={selectedOrders}
                                    selectOrder={selectOrder}
                                    item={item}
                                    setDetailsPopUpIsOpen={setDetailsPopUpIsOpen}
                                    setDataFromPopUp={setDataFromPopUp}
                                />
                            );
                        })
                    ) : (
                        <Loader />
                    )}
                </tbody>
            </table>
            {isOpen ? <DetailedInformationPopUp data={data} setDetailsPopUpIsOpen={setDetailsPopUpIsOpen} /> : null}
        </>
    );
};

export default JournalItemsTableAllData;
