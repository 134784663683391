import React, { useEffect, useRef } from 'react';
import { clearSignRequestState } from '../reducers';
import { fileActions } from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const useSign = (fileName) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const signRequestState = useSelector((state) => state.file.signRequestState);
    const getSignQrCodeIntervalId = useRef();

    useEffect(() => {
        if (signRequestState) {
            dispatch(clearSignRequestState());
        }
        if (getSignQrCodeIntervalId.current) {
            clearInterval(getSignQrCodeIntervalId.current);
        }
    }, []);

    useEffect(() => {
        if (signRequestState) {
            if (fileName) {
                getSignQrCodeIntervalId.current = setInterval(() => {
                    dispatch(
                        fileActions.getSignQrCode(
                            {
                                fileName,
                                getSignQrCodeIntervalId: getSignQrCodeIntervalId.current,
                            },
                            navigate
                        )
                    );
                }, 2000);
                dispatch(
                    fileActions.getSignQrCode({
                        fileName,
                        getSignQrCodeIntervalId: getSignQrCodeIntervalId.current,
                    })
                );
            }
        }
        return () => {
            clearInterval(getSignQrCodeIntervalId.current);
        };
    }, [signRequestState]);
};

export default useSign;
