//Core
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
//Actions
import { journalActions } from '../../actions';
import { clearJournalFilesData } from '../../reducers';

//Components
import { DownloadFile, JournalItemsTable, NavLinkBtn, Pagination } from '../../components';
import Styles from '../../components/JournalItemsTable/styles.module.css';

const JournalItemPageByFile = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { fileName, tab } = useParams();
    const journalFileData = useSelector((state) => state.journalData.journalFileData);

    useEffect(() => {
        dispatch(
            journalActions.getJournalFilesData(navigate, {
                file: fileName,
                state: tab === 'deleted' ? tab.toUpperCase() : null,
            })
        );
        return () => {
            dispatch(clearJournalFilesData());
        };
    }, []);

    const paginationClick = (nav, i) => {
        dispatch(clearJournalFilesData());
        dispatch(
            journalActions.getJournalFilesData(nav, {
                file: fileName,
                state: tab === 'deleted' ? tab.toUpperCase() : null,
                page: i,
            })
        );
    };

    return (
        <section className={Styles.container}>
            <div className={Styles.title_wrap}>
                <div className={Styles.title}>Журнал</div>
                <NavLinkBtn to="/journal/files" title="Журнал" />
            </div>
            <JournalItemsTable journalFileData={journalFileData} fileName={fileName} />
            <Pagination totalPages={journalFileData?.totalPages} action={paginationClick} />
            <NavLinkBtn to="/" title="На головну" />
            <DownloadFile fileName={fileName} />
        </section>
    );
};

export default JournalItemPageByFile;
