//API
import { fileAPI, loggerAPI } from '../api';
//Helpers
import { isValidJSON, errorResponseHelper } from '../helpers';
//Actions
import {
    setDbfFileData,
    setLoader,
    clearFileData,
    setSignRequestState,
    saveQrCodeSignURL,
    saveQrCodeStampURL,
    clearSignRequestState,
    clearQrCodeSignURL,
    clearQrCodeStampURL,
    setStateStamped,
    clearStateStamped,
    setUpdateFileData,
    setIsFileUpdated,
} from '../reducers';

const fileActions = {
    uploadFileAction: (formData, navigate) => async (dispatch, getState) => {
        try {
            const state = getState();
            const apiKey = state.authData.data?.apiKey;
            dispatch(setLoader(true));
            const response = await fileAPI.uploadFile(formData, apiKey);
            if (!response.ok) {
                await errorResponseHelper(response);
            }
            const fileData = await response.json();
            dispatch(setDbfFileData(fileData));
        } catch (e) {
            console.log(e);
            loggerAPI.log({
                logger: 'prexp24b',
                msg: e,
                level: 'INFO',
            });
            navigate('/error', {
                replace: true,
                state: {
                    text: isValidJSON(e?.message) ? JSON.parse(e.message) : null,
                    buttons: [
                        { path: '/', text: 'На головну' },
                        { path: '/journal/files', text: 'Журнал' },
                    ],
                },
            });
        } finally {
            dispatch(setLoader(false));
        }
    },
    upDateFileAction: (editData, navigate) => async (dispatch, getState) => {
        try {
            const state = getState();
            const apiKey = state.authData.data?.apiKey;
            dispatch(setLoader(true));
            const response = await fileAPI.upDateFileData(editData, apiKey);
            if (!response.ok) {
                await errorResponseHelper(response);
            }
            const updatedData = await response.json();
            dispatch(setUpdateFileData(updatedData));
            dispatch(setIsFileUpdated(true));
        } catch (e) {
            console.log(e);
            loggerAPI.log({
                logger: 'prexp24b',
                msg: e,
                level: 'INFO',
            });
            navigate('/error', {
                replace: true,
                state: {
                    text: isValidJSON(e?.message) ? JSON.parse(e.message) : null,
                    buttons: [
                        { path: '/', text: 'На головну' },
                        { path: '/journal/files', text: 'Журнал' },
                    ],
                },
            });
        } finally {
            dispatch(setLoader(false));
        }
    },
    saveSignedEcpData: (signedEcpData, navigate) => async (dispatch, getState) => {
        try {
            const state = getState();
            const apiKey = state.authData.data?.apiKey;
            dispatch(setLoader(true));
            const response = await fileAPI.saveEcpData(signedEcpData, apiKey);

            if (!response.ok) {
                await errorResponseHelper(response);
            }

            if (response.status === 204) {
                dispatch(clearFileData());
                navigate('/success', {
                    replace: true,
                    state: {
                        buttons: [
                            { path: '/', text: 'На головну' },
                            { path: '/journal/files', text: 'Журнал' },
                        ],
                    },
                });
            }
        } catch (e) {
            console.log(e);
            loggerAPI.log({
                logger: 'prexp24b',
                msg: e,
                level: 'INFO',
            });
            navigate('/error', {
                replace: true,
                state: {
                    text: isValidJSON(e?.message) ? JSON.parse(e.message) : null,
                    buttons: [
                        { path: '/', text: 'На головну' },
                        { path: '/journal/files', text: 'Журнал' },
                    ],
                },
            });
        } finally {
            dispatch(setLoader(false));
        }
    },
    signRequestAction: (data, navigate) => async (dispatch, getState) => {
        try {
            const state = getState();
            const apiKey = state.authData.data?.apiKey;
            dispatch(setLoader(true));
            const response = await fileAPI.signRequest(data, apiKey);
            if (!response.ok) {
                await errorResponseHelper(response);
            }
            const stateSignRequesr = await response.json();
            if (stateSignRequesr.state === 'OK') {
                dispatch(setSignRequestState());
            }
        } catch (e) {
            console.log(e);
            loggerAPI.log({
                logger: 'prexp24b',
                msg: e,
                level: 'INFO',
            });
            navigate('/error', {
                replace: true,
                state: {
                    text: isValidJSON(e?.message) ? JSON.parse(e.message) : null,
                    buttons: [
                        { path: '/', text: 'На головну' },
                        { path: '/journal/files', text: 'Журнал' },
                    ],
                },
            });
        } finally {
            dispatch(setLoader(false));
        }
    },
    getSignQrCode: (data, navigate) => async (dispatch, getState) => {
        try {
            const state = getState();
            const apiKey = state.authData.data?.apiKey;
            dispatch(setLoader(true));
            const response = await fileAPI.getSignQrCode(data.fileName, apiKey);
            if (!response.ok) {
                await errorResponseHelper(response);
            }
            const qrCodeLink = await response.json();
            if (qrCodeLink.state === 'OK') {
                clearInterval(data.getSignQrCodeIntervalId);
                dispatch(clearSignRequestState());
                dispatch(saveQrCodeSignURL(qrCodeLink.signUrl));
                dispatch(saveQrCodeStampURL(qrCodeLink.stampUrl));
            }
        } catch (e) {
            console.log(e);
            loggerAPI.log({
                logger: 'prexp24b',
                msg: e,
                level: 'INFO',
            });
            clearInterval(data.getSignQrCodeIntervalId);
            dispatch(clearSignRequestState());
            navigate('/error', {
                replace: true,
                state: {
                    text: isValidJSON(e?.message) ? JSON.parse(e.message) : null,
                    buttons: [
                        { path: '/', text: 'На головну' },
                        { path: '/journal/files', text: 'Журнал' },
                    ],
                },
            });
        } finally {
            dispatch(setLoader(false));
        }
    },
    getSignFileState: (data, navigate) => async (dispatch, getState) => {
        try {
            const state = getState();
            const apiKey = state.authData.data?.apiKey;
            dispatch(setLoader(true));
            const response = await fileAPI.getSignFileState(data.fileName, apiKey);
            if (!response.ok) {
                await errorResponseHelper(response);
            }
            const qrState = await response.json();

            const role = state?.authData?.data?.roles?.[0];

            if (qrState.state === 'STAMPED') {
                dispatch(clearQrCodeStampURL());
                dispatch(setStateStamped());
            }

            if (
                qrState.state === 'SIGNED' ||
                (qrState.state === 'OWNER_SIGNED' && role === 'STATE_OWNER') ||
                (qrState.state === 'ACCOUNTANT_SIGNED' && role === 'STATE_ACCOUNTANT')
            ) {
                clearInterval(data.waitScanQrCodeInterval);
                dispatch(clearQrCodeSignURL());
                dispatch(clearQrCodeStampURL());
                dispatch(clearStateStamped());
                navigate('/success', {
                    replace: true,
                    state: {
                        buttons: [
                            { path: '/', text: 'На головну' },
                            { path: '/journal/files', text: 'Журнал' },
                        ],
                    },
                });
            }
            if (qrState.state === 'ERROR') {
                clearInterval(data.waitScanQrCodeInterval);
                dispatch(clearQrCodeSignURL());
                dispatch(clearQrCodeStampURL());
                dispatch(clearStateStamped());
                navigate('/error', {
                    replace: true,
                    state: {
                        buttons: [
                            { path: '/', text: 'На головну' },
                            { path: '/journal/files', text: 'Журнал' },
                        ],
                    },
                });
            }
        } catch (e) {
            console.log(e);
            loggerAPI.log({
                logger: 'prexp24b',
                msg: e,
                level: 'INFO',
            });
            clearInterval(data.waitScanQrCodeInterval);
            dispatch(clearQrCodeSignURL());
            dispatch(clearQrCodeStampURL());
            dispatch(clearStateStamped());
            navigate('/error', {
                replace: true,
                state: {
                    text: isValidJSON(e?.message) ? JSON.parse(e.message) : null,
                    buttons: [
                        { path: '/', text: 'На головну' },
                        { path: '/journal/files', text: 'Журнал' },
                    ],
                },
            });
        } finally {
            dispatch(setLoader(false));
        }
    },
};

export default fileActions;
