// Core
import { onCLS, onFID, onLCP, onINP, onFCP, onTTFB } from 'web-vitals';
import ReactGA from 'react-ga4';

export const sendToGoogleAnalytics = ({ name, delta, value, id }) => {
    let metric_result = '';

    switch (name) {
        case 'LCP':
            metric_result = value <= 2500 ? 'Good' : value > 4000 ? 'Poor' : 'NI';
            break;
        case 'FID':
            metric_result = value <= 100 ? 'Good' : value > 300 ? 'Poor' : 'NI';
            break;
        case 'INP':
            metric_result = value <= 200 ? 'Good' : value > 500 ? 'Poor' : 'NI';
            break;
        case 'CLS':
            metric_result = value <= 0.1 ? 'Good' : value > 0.25 ? 'Poor' : 'NI';
            break;
        case 'TTFB':
            metric_result = value <= 3000 ? 'Good' : value > 4000 ? 'Poor' : 'NI';
            break;
        case 'FCP':
            metric_result = value <= 1800 ? 'Good' : value > 3000 ? 'Poor' : 'NI';
            break;
        default:
            break;
    }

    ReactGA.gtag('event', name, {
        value: delta,
        metric_id: id,
        metric_value: value,
        metric_delta: delta,
        ...(metric_result ? { metric_result } : {}),
    });
};

export const reportWebVitals = (onPerfEntry) => {
    if (onPerfEntry && onPerfEntry instanceof Function) {
        onFCP(onPerfEntry);
        onLCP(onPerfEntry);
        onFID(onPerfEntry);
        onINP(onPerfEntry);
        onCLS(onPerfEntry);
        onTTFB(onPerfEntry);
    }
};
