//Core
import React from 'react';
import { useLocation } from 'react-router-dom';
//Styles
import Styles from './styles.module.css';
//Images
import errorIcon from '../../theme/assets/icons/error_icon.svg';
//Components
import { NavLinkBtn } from '../../components';

const ErrorPage = () => {
    const { state } = useLocation();

    const getErrorText = (type) => {
        switch (type) {
            case 'AlreadyUploadedPain013DbfFileException':
                return 'Файл вже завантажено';
            case 'DbfFileNotFoundException':
                return 'Файл не знайдено';
            case 'InvalidPain013DbfFileException':
                return "Інформація або ім'я файлу не коректні";
            case 'EkbServiceException':
            case 'InvalidP24bAuthTokenException':
            case 'UserUnauthorizedException':
                return 'Сталася помилка при авторизації. Спробуйте пізніше';
            case 'NoAccess':
                return 'Доступ до данного розділу обмежено';
            default:
                return 'Щось пішло не так';
        }
    };

    return (
        <section className={Styles.container}>
            <div className={Styles.wrap}>
                <img className={Styles.error_icon} src={errorIcon} alt="error_icon" />
                <div className={Styles.caption}>Помилка</div>
                <div className={Styles.info}>{state && state?.text?.message?.type ? getErrorText(state.text.message.type) : 'Щось пішло не так'}</div>
                <div className={Styles.nav_wrap}>
                    {state
                        ? state?.buttons?.map((item, i) => {
                              return <NavLinkBtn class={i === 1 ? Styles.nav_link : ''} to={item.path} title={item.text} />;
                          })
                        : null}
                </div>
            </div>
        </section>
    );
};

export default ErrorPage;
