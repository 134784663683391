//Core
import React from 'react';
import { useLocation } from 'react-router-dom';
//Styles
import Styles from './styles.module.css';
//Images
import successIcon from '../../theme/assets/icons/success_icon.svg';
//Components
import { NavLinkBtn } from '../../components';

const SuccessPage = () => {
    const { state } = useLocation();

    return (
        <section className={Styles.container}>
            <div className={Styles.wrap}>
                <img className={Styles.error_icon} src={successIcon} alt="error_icon" />
                <div className={Styles.caption}>Успішно</div>
                <div className={Styles.nav_wrap}>
                    {state
                        ? state?.buttons?.map((item, i) => {
                              return <NavLinkBtn class={i === 1 ? Styles.nav_link : ''} to={item.path} title={item.text} />;
                          })
                        : null}
                </div>
            </div>
        </section>
    );
};

export default SuccessPage;
