//Core
import React, { useState } from 'react';
//Styles
import Styles from './styles.module.css';
//Components
import { NavLinkBtn, JournalItem, Loader, DownloadFile, DetailedInformationPopUp } from '../../components';
//Hooks
import { useDetailsPopUp } from '../../hooks';

const JournalItemsTable = ({ journalFileData, fileName }) => {
    const [openId, setOpenId] = useState(null);
    const [isOpen, setDetailsPopUpIsOpen, data, setDataFromPopUp] = useDetailsPopUp();
    return (
        <>
            <table className={`${Styles.tableGrid} ${Styles.table}`}>
                <thead className={Styles.table_item}>
                    <tr>
                        {/*<span className={Styles.checkbox}>*/}
                        {/*<input type="checkbox" id="all" name="all" className={Styles.input_checkbox} onChange={(event) => console.log('check')} />*/}
                        {/*<label className={Styles.label_checkbox} htmlFor="all" />*/}
                        {/*</span>*/}
                        {/*<span className={Styles.docNum}>Номер документу</span>*/}
                        <th>
                            <span className={Styles.debtorAccount}>Номер рахунку боржника</span>
                        </th>
                        <th className={Styles.sumContainer}>
                            <span className={Styles.sum}>Сума платежу</span>
                        </th>
                        <th>
                            <span className={Styles.purpose}>Призначення платежу</span>
                        </th>
                        <th>
                            <span className={Styles.executorOkpo}>ЄДРОУП/ІПН боржника</span>
                        </th>
                        <th>
                            <span className={Styles.debtorName}>Найменування платника</span>
                        </th>
                        <th>
                            <span className={Styles.result}>Результат обробки</span>
                        </th>
                        <th>
                            <span className={Styles.modifiedAt}>Дата опрацювання</span>
                        </th>
                        <th>
                            <span className={Styles.state}>Статус</span>
                        </th>
                        <th>
                            <span className={Styles.action}></span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {journalFileData?.content ? (
                        journalFileData.content.map((item) => {
                            return (
                                <JournalItem
                                    setOpenId={setOpenId}
                                    openId={openId}
                                    item={item}
                                    fileName={fileName}
                                    setDetailsPopUpIsOpen={setDetailsPopUpIsOpen}
                                    setDataFromPopUp={setDataFromPopUp}
                                />
                            );
                        })
                    ) : (
                        <Loader />
                    )}
                </tbody>
            </table>
            {isOpen ? <DetailedInformationPopUp data={data} setDetailsPopUpIsOpen={setDetailsPopUpIsOpen} /> : null}
        </>
    );
};

export default JournalItemsTable;
