const loggerAPI = {
    log: (data) => {
        return fetch(`/api/v1/log`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });
    },
};

export default loggerAPI;
