const journalAPI = {
    getJournalFiles: (apiKey, params = {}) => {
        //TO DO Create URL Helper
        let url = `/api/v1/payment-orders/files?`;
        for (const [key, value] of Object.entries(params)) {
            if (Array.isArray(value) && value.length) {
                for (const item of value) {
                    url += `${key}=${item}&`;
                }
            } else {
                url += `${key}=${value}&`;
            }
        }
        //******
        return fetch(url, {
            headers: {
                accept: 'application/json',
                'X-API-KEY': apiKey,
            },
            method: 'GET',
        });
    },
    getJournalFileData: (apiKey, params = {}) => {
        //TO DO Create URL Helper
        let url = `/api/v1/payment-orders?sortDirection=DESC&`;
        for (const [key, value] of Object.entries(params)) {
            if (Array.isArray(value) && value.length) {
                for (const item of value) {
                    url += `${key}=${item}&`;
                }
            } else {
                if (key && value) {
                    url += `${key}=${value}&`;
                }
            }
        }
        //*****
        return fetch(url, {
            headers: {
                accept: 'application/json',
                'X-API-KEY': apiKey,
            },
            method: 'GET',
        });
    },
    cancelPayment: (id, apiKey) => {
        return fetch(`/api/v1/payment-orders/${id}/cancel`, {
            headers: {
                accept: 'application/json',
                'X-API-KEY': apiKey,
            },
            method: 'POST',
        });
    },
    downloadFile: (fileName, apiKey) => {
        return fetch(`/api/v1/dbf-file/${fileName}`, {
            headers: {
                accept: 'application/dbf',
                'X-API-KEY': apiKey,
            },
            method: 'GET',
        });
    },
    downloadFileByOrders: (selectedOrders, apiKey) => {
        return fetch(`/api/v1/dbf-file/download-by-orders`, {
            headers: {
                accept: 'application/dbf',
                'Content-Type': 'application/json',
                'X-API-KEY': apiKey,
            },
            method: 'POST',
            body: JSON.stringify({
                paymentOrderIds: [...selectedOrders],
            }),
        });
    },
    getLogs: (id, apiKey) => {
        return fetch(`/api/v1/payment-orders/${id}/change-logs`, {
            headers: {
                accept: 'application/json',
                'X-API-KEY': apiKey,
            },
            method: 'GET',
        });
    },
    removeFile: (fileName, apiKey) => {
        return fetch(`/api/v1/dbf-file/${fileName}`, {
            headers: {
                accept: 'application/json',
                'X-API-KEY': apiKey,
            },
            method: 'DELETE',
        });
    },
    downloadPdfFile: (id, apiKey) => {
        return fetch(`/api/v1/payment-orders/payment-instruction-pdf/${id}`, {
            headers: {
                accept: 'application/pdf',
                'X-API-KEY': apiKey,
            },
            method: 'GET',
        });
    },
};

export default journalAPI;
