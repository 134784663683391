import React from 'react';
import { ErrorPage } from '../../pages';
import { loggerAPI } from '../../api';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        loggerAPI.log({
            logger: 'prexp24b',
            msg: JSON.stringify({
                error,
                errorInfo,
            }),
            level: 'INFO',
        });
    }

    render() {
        if (this.state.hasError) {
            return <ErrorPage />;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;
