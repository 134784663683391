// Core
import { createSlice } from '@reduxjs/toolkit';

const uiSlice = createSlice({
    name: 'uiData',
    initialState: {
        loader: false,
    },
    reducers: {
        setLoader: (state, action) => {
            state.loader = action.payload;
        },
    },
});

export const { setLoader } = uiSlice.actions;

export default uiSlice.reducer;
