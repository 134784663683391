//Core
import React from 'react';
//Styles
import Styles from './styles.module.css';

const Button = (props) => {
    return (
        <button className={`${Styles.btn} ${props?.class}`} onClick={props?.onClick} type={props?.type ? props.type : 'button'}>
            {props?.title}
        </button>
    );
};

export default Button;
