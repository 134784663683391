import React, { useState } from 'react';

const useDetailsPopUp = () => {
    const [isOpen, setDetailsPopUpIsOpen] = useState(false);
    const [data, setDataFromPopUp] = useState(null);

    return [isOpen, setDetailsPopUpIsOpen, data, setDataFromPopUp];
};

export default useDetailsPopUp;
