import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga4';

import { reportWebVitals, sendToGoogleAnalytics } from './reportWebVitals';

import { store } from './store';

import Load from './Load';
import { ErrorBoundary } from './components';
import './index.css';

ReactGA.initialize(process.env.REACT_APP_GA_ID, {
    gaOptions: {
        debug_mode: true,
    },
    gtagOptions: {
        debug_mode: true,
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <Router>
            <ErrorBoundary>
                <Load />
            </ErrorBoundary>
        </Router>
    </Provider>
);

reportWebVitals(sendToGoogleAnalytics);
