const fileAPI = {
    uploadFile: (formData, apiKey) => {
        return fetch(`/api/v1/dbf-file/upload`, {
            headers: {
                accept: 'application/json',
                'X-API-KEY': apiKey,
            },
            method: 'POST',
            body: formData,
        });
    },
    upDateFileData: (data, apiKey) => {
        return fetch(`/api/v1/payment-orders`, {
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': apiKey,
            },
            method: 'PATCH',
            body: JSON.stringify(data),
        });
    },
    saveEcpData: (ecpData, apiKey) => {
        return fetch(`/api/v1/save-sign`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': apiKey,
            },
            body: JSON.stringify(ecpData),
        });
    },
    signRequest: (data, apiKey) => {
        return fetch(`/api/v1/dbf-file/sign`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': apiKey,
            },
            body: JSON.stringify(data),
        });
    },
    getSignQrCode: (fileName, apiKey) => {
        return fetch(`/api/v1/dbf-file/${fileName}/sign-qr-code`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': apiKey,
            },
        });
    },
    getSignFileState: (fileName, apiKey) => {
        return fetch(`/api/v1/dbf-file/${fileName}/state`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': apiKey,
            },
        });
    },
};

export default fileAPI;
