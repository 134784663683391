//Core
import React from 'react';
//Styles
import Styles from './styles.module.css';
//Components
import { NavLinkBtn, UploadFile } from '../../components';

const UploadPage = () => {
    return (
        <section className={Styles.container}>
            <UploadFile />
            <NavLinkBtn title="Журнал" to="/journal/files" />
        </section>
    );
};

export default UploadPage;
