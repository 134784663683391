// Core

// Style
import Styles from './styles.module.css';

export default function Loader(props) {
    return (
        <div className={Styles.spinner_wrap}>
            <span className={Styles.spinner} />
        </div>
    );
}
