//Core
import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
//Styles
import Styles from './styles.module.css';

const Pagination = ({ totalPages, action }) => {
    const navigate = useNavigate();
    const [currentPaginationPage, setCurrentPaginationPage] = useState(0);
    const setPaginationStyle = (pageNumber) => {
        return currentPaginationPage === pageNumber ? Styles.active_page : Styles.page;
    };

    const getPages = useMemo(() => {
        const siblingCount = 2;
        const totalPageNumbers = siblingCount + 5;

        const range = (start, end) => {
            let length = end - start + 1;
            return Array.from({ length }, (_, idx) => idx + start);
        };

        if (totalPageNumbers >= totalPages) {
            return range(1, totalPages);
        }

        const leftSiblingIndex = Math.max(currentPaginationPage - siblingCount + 1, 1);
        const rightSiblingIndex = Math.min(currentPaginationPage + siblingCount + 1, totalPages);

        const shouldShowLeftDots = leftSiblingIndex > 2;
        const shouldShowRightDots = rightSiblingIndex < totalPages - 2;

        const firstPageIndex = 1;
        const lastPageIndex = totalPages;

        if (!shouldShowLeftDots && shouldShowRightDots) {
            let leftItemCount = 3 + 2 * siblingCount;
            let leftRange = range(1, leftItemCount);

            return [...leftRange, '...', totalPages];
        }

        if (shouldShowLeftDots && !shouldShowRightDots) {
            let rightItemCount = 3 + 2 * siblingCount;
            let rightRange = range(totalPages - rightItemCount + 1, totalPages);
            return [firstPageIndex, '...', ...rightRange];
        }

        if (shouldShowLeftDots && shouldShowRightDots) {
            let middleRange = range(leftSiblingIndex, rightSiblingIndex);
            return [firstPageIndex, '...', ...middleRange, '...', lastPageIndex];
        }
    }, [totalPages, currentPaginationPage]);

    return totalPages > 1 ? (
        <div className={Styles.paginationContainer}>
            {getPages.map((item) => {
                return (
                    <div
                        className={setPaginationStyle(item - 1)}
                        // style={currentPaginationPage === i ? { color: '#2c6dff' } : null}
                        onClick={() => {
                            if (item === '...') {
                                return;
                            }
                            action(navigate, item - 1);
                            setCurrentPaginationPage(item - 1);
                            window.scrollTo(0, 0);
                        }}
                    >
                        <span>{item}</span>
                    </div>
                );
            })}
        </div>
    ) : null;
};

export default Pagination;
