//Core
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//Actions
import { paramsActions } from './actions';
//Components
import App from './App';

const Load = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const env = useSelector((state) => state.authData.params);

    useEffect(() => {
        dispatch(paramsActions.getVariable(navigate));
    }, []);

    return env?.REACT_APP_P24URL ? <App /> : null;
};

export default Load;
